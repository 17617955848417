body {
    font-size: 14px;
    font-family: 'SourceSansPro', Arial, Helvetica, sans-serif;
}


/* Fonts */

/* Source Sans */

@font-face {
    font-family: 'SourceSansPro';
    src: url('../fonts/source-sans-pro/SourceSansPro-Regular.otf') format("opentype");
    font-weight: 400;
}
@font-face {
    font-family: 'SourceSansPro';
    src: url('../fonts/source-sans-pro/SourceSansPro-Semibold.otf') format("opentype");
    font-weight: 600;
}
@font-face {
    font-family: 'SourceSansPro';
    src: url('../fonts/source-sans-pro/SourceSansPro-Black.otf') format("opentype");
    font-weight: 800;
}
@font-face {
    font-family: 'SourceSansPro';
    src: url('../fonts/source-sans-pro/SourceSansPro-Bold.otf') format("opentype");
    font-weight: 700;
}

/* Source Serif */

@font-face {
    font-family: 'SourceSerifPro';
    src: url('../fonts/source-serif-pro/SourceSerifPro-Regular.otf') format("opentype");
    font-weight: 400;
}
@font-face {
    font-family: 'SourceSerifPro';
    src: url('../fonts/source-serif-pro/SourceSerifPro-Bold.otf') format("opentype");
    font-weight: 700;
}

.documentFirstHeading {
    border-bottom: 0;
}

.tileFooter a, .more a, .carousel-caption a, #navbar a, .chevronRedLink, .chevronBlackBoldLink, .uppercase-link {
    font-family: "SourceSansPro", sans-serif;
    letter-spacing: 0.075em;
    text-transform: uppercase;
}
a:focus, a:hover {
    color: #000;
}

address, p {
    font-family: "SourceSerifPro", "Times New Roman", Times, serif;
    hyphens: auto;
}

/*faun9 - Startseite ab "Aktuelles" gleiche Breite wie darüber*/
.row {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
}

.row-only-left-padding div {
        padding-left: 0;
        padding-right: 0;
}

@media (min-width: @screen-md) {
        .row-only-left-padding div + div {
                padding-left: 30px;
        }
}

mark {
    font-weight: 400;
    letter-spacing: 0.075em;
    background-color: #000;
    color: #fff;
    display: inline-block;
    line-height: 1em;
    margin: -0.15em 0 -0.3em;
    padding: 0.15em 0.2em 0.3em;
    text-transform: uppercase;
}
mark a {
    color: #fff;
}
mark a:hover {
    color: #fff;
    text-decoration: none;
}
footer {
    margin-top: 2em;
    background-color: #e1e1e1;
}
/** Klasse wegen Reihenfolge der Auswertung mit .frontend, zumindest in FF */
.footer a {
    color: #000;
    letter-spacing: normal;
    text-transform: none;
}
footer a::before {
    content: "› ";
}
footer .container {
    padding-left: 0;
    padding-right: 0;
}
footer ul {
    padding-left: 0;
    list-style: none;
}
strong {
    font-weight: 600;
}
.bordered {
    border: solid;
    border-width: thin;
    padding: 0.5em !important;
}
.bordered> li:last-child {
    border-top: solid;
    border-width: thin;
    margin: 2.5em -0.5em 0;
    padding: 0.3em 0.5em 0;
}
.borderedList {
    /* Pseudo Class needed for the list items. */
    padding-left: 0;
}
.borderedList> li {
    border: solid;
    border-bottom: none;
    border-width: thin;
    padding-top: 0.3em;
    padding-bottom: 0.5em;
    padding-left: 0.4em;
}
.borderedList> li:last-child {
    border-bottom: solid;
    border-width: thin;
}

#viewlet-above-content {
  margin: 1em 0 1em 0;
}

.plone-breadcrumb ol {
        list-style-type: none;
        margin: 0;
        padding: 0;
        line-height: 10px;
}

.plone-breadcrumb ol > li {
        display: inline;
        font-size: 12px;
}

.plone-breadcrumb ol > li + li:before {
  content: "\A/\A";
}

.plone-breadcrumb li a, .plone-breadcrumb li span, .breadcrumb li a {
    color: #000;
    text-transform: uppercase;
}
.plone-breadcrumb #breadcrumbs-current, .breadcrumb .active {
    font-weight: 600;
}
.plone-breadcrumb ol:after {
        white-space: pre;
    content: "\A—";
}

.carousel img {
    object-fit: cover;
}
.chevronBlackBoldLink {
    color: #000;
    font-weight: bold;
}
.chevronBlackBoldLink::before {
    content: "› ";
}
.more a, .chevronRedLink {
    font-size: 0.8em;
}
.more a::before, .chevronRedLink::before {
    content: "› ";
}
.dropdown-menu {
    border: none;
    border-image-width: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    height: 398.9px;
    left: 0;
    position: absolute;
    right: 0;
}
.dropdown-menu ul {
    padding-left: 0;
}
.yamm .container {
    position: static;
}
.dropdown-menu a {
    color: #fff;
}
.featurebox h4 {
    font-size: 1.1em;
}
.featurebox h4 a {
    font-family: 'SourceSansPro';
    font-weight: 700;
    color: #000;
    text-transform: none;
}
.featurebox h4 a:hover {
    text-decoration: none;
}
.jumbotron {
    background-color: #c00200;
    text-align: center;
}
.jumbotron * mark {
    background-color: #fff;
}
.jumbotron * mark a {
    color: #c00301;
}
.menu-left {
    border-right: 1px solid #fff;
    font-weight: bold;
}
.menu-left, .menu-right {
    margin-top: 2em;
}
.nav > li:first-child > a {
    padding-left: 0;
    /*margin-left: -15px;*/
}
.nav > li > a {
    padding-left: 10px;
    padding-right: 10px;
}
.std-entry {
    color: #000;
    letter-spacing: normal;
    text-transform: none;
}
.portletHeader, .section-header, .section-startseite h1, .section-startseite h2, .section-startseite h3, .section-startseite h4, .section-startseite h5, .section-startseite h6 {
    color: #000;
    letter-spacing: 0.075em;
    text-transform: uppercase;
}
.section-startseite h1 {
    color: #fff;
    text-align: center;
	line-height: 0.8;
}
.section-startseite h1::after {
    /* Don't ask me how and why it works */
    white-space: pre;
    content: "\A—";
}
/*faun9 - für verbesserte Lesbarkeit der Überschrift shadow hinzugefügt*/
.section-startseite h1 a {
    color: #fff;
    text-shadow: 1px 1px 3px black;
    font-size: 0.75em;
}
.portletHeader, .section-header, .section-startseite h2 {
    border-top: solid;
    border-width: thick;
    color: #000;
    margin-top: 1em;
    padding-top: 0.4em;
    margin-bottom: 0.5em;
    font-size: 1.2em;
}
.portletHeader, .section-header, .section-startseite h2 a {
    color: #000;
}
.section-startseite h2 a.weiteres, .weiteres a {
    color: #c00301 !important;
    float: right;
    font-size: 0.8em;
    font-weight: normal;
}
.section-startseite h2 a.weiteres::before, .weiteres a::before {
    content: "› ";
}
.section-startseite h3 {
    font-size: 1.3em;
}
.section-startseite ul {
    list-style: none;
}
.dropdown-toggle {
  font-weight: bold;
}
.syndikatswahl {
    margin-top: 1em;
    margin-bottom: -1em;
}

/* Locals */
.local .jumbotron {
  width: 100%;
  color: white;
  text-align: left;
  margin-bottom: 1em;
}

.local .jumbotron .container {
    max-width: 960px;
}

.local .jumbotron h1 {
  font-weight: 600;
  font-size: 22px;
}

.local .jumbotron h1::after {
  white-space: pre;
  content: "\A—";
}

.local .jumbotron h1 span {
    font-weight: 400;
}

.local .jumbotron h1 span::before {
  content: "–\A";
}

.local .jumbotron .info p {
  font-family: 'SourceSansPro', Arial, Helvetica, sans-serif !important;
  font-size: 14px !important;
}

.local .jumbotron .info a {
  color: #fff;
  text-decoration: underline;
}

.local .breadcrumb {
  margin-bottom: 2em;
}

.local .external-website img {
        margin-top: 2em;
}

/* Auflistungen */

.documentFirstHeading {
  position: relative;
}

.documentFirstHeading nav {
  font-size: 14px;
  position: absolute;
  right: 0;
  bottom: 13px;
}

.documentFirstHeading nav ul {
  margin: 0;
  list-style-type: none;
}

nav ul.mode_selector li {
  display: inline;
}

nav ul.mode_selector li a {
  display: inline-block;
  margin-left: .5em;
}

.documentByLine {
  color: #696969;
  margin-bottom: 10px;
}

.tileItem {
        border-bottom: 1px solid #000;
        margin-bottom: 2.5em;
        overflow: hidden;
}

.tileHeadline {
        font-size: 1.1em;
}

.tileHeadline a {
        color: #000;
        text-transform: none;
}

.tileBody .description {
        font-size: 1.1em;
}

.tileFooter a::before {
    content: "› ";
}

/** Portlets **/

.portletHeader {
  font-family: "SourceSansPro", sans-serif;
  font-weight: 700 !important;
  line-height: 1.1;
  margin-top: 0;
}

.portletHeader a {
  color: #000 !important;
}

.portlet .portletContent > ul {
  padding: 0;
  list-style: none;
  position: relative;
}

.portlet .portletContent > *, .portlet .portletContent > div:not(.portalMessage) {
  padding: 10px 0;
  margin: 0;
  font-weight: 300;
  color: #696969;
}

.portlet .portletContent > ul > li a {
  display: block;
  padding: 10px 15px 26px 0;
  position: relative;
  z-index: 1;
}

.portlet .portletContent > ul .portletItemDetails {
  display: block;
  z-index: 0;
  padding: 0 15px 10px 0;
  font-weight: 100;
  position: relative;
  margin-top: -26px;
  font-size: 12px;
  color: #777777;
}

.portlet .portletFooter {
  padding: 10px 0;
  margin-bottom: 1em;
  font-size: 12px;
  font-weight: 300;
  background-color: #fafafa;
}

/** Tiles **/

.std-entry.contenttype-event {
        font-weight: 600;
}

.localselecttile img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
}
/** Panels **/

.panel {
        border-radius: 0;
        border-color: #000;
}

.panel-default > .panel-heading  {
        border-color: #000 !important;
        background-color: #fafafa !important;
}

/** Auflistung - Termine **/

.sr-only, .hiddenStructure, .crud-form .header-select {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: #fff;
  background-color: #000;
}

.vevent div.cal_date {
  float: left;
  vertical-align: top;
  text-align: center;
  margin: 42px 1.5em .5em 0;
  background-color: #c00301;
  min-width: 4.4em;
  overflow: hidden;
  width: 100px;
}

.cal_month {
  background: #000;
  font-weight: 400;
  display: block;
  color: #fff;
  padding: 6px 12px;
}

.cal_day {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  display: block;
}

.cal_wkday {
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  display: block;
  padding: 0 12px 6px;
}

@media (min-width: 768px) {
    .navbar-nav> li {
        float: none;
        display: inline-block;
    }
    .navbar-nav> .navbar-right {
        margin-right: 0;
    }
    .navbar-inverse .navbar-nav> li> a {
        color: #fff;
    }
}

/*faun9 - responsive-anpassungen für carousel*/
@media (max-width: 767px) {

	#carousel p.byline {
		font-size: 15px;
		display: none;
	}

	.section-startseite h1 a {
		font-size: 33px;
	}
}

/*faun9 - "carousel div.carousel-inner" eingefügt, damit bei Artikeln ohne Bild das Element nicht zusammenfällt, Höhe schwankte zuvor*/
#carousel {
    height: 460px;
    background-color: #c00200;
    text-align: center;
}

#carousel div.carousel-inner div.item img {
    width: 100%;
    height: 460px;
}

#carousel div.carousel-inner {
    width: 100%;
    height: 460px;
}

#carousel h1 {
    font-size: 63px;
}

.carousel-caption {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*faun9 - für verbesserte Lesbarkeit des Textes shadow und leicht transparenten Hintergrund hinzugefügt*/
#carousel p.byline {
    margin-bottom: 15px;
    font-size: 21px;
    font-weight: 200;
    text-shadow: 1px 1px 3px black;
}

#carousel * mark {
    background-color: #fff;
}
#carousel * mark a {
    color: #c00301;
}

/* Hier folgt das Chaos */

.link-parent::before {
    content: "↶ ";
}

.link-parent {
    font-size: 1.5em;
    font-weight: 600;
}

label {
    font-weight: 600;
}

.configlets {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.configlets li {
    padding-left: 0;
}

.portletHeader {
    font-weight: 600;
}

.plone-toolbar-container a {
    text-transform: none;
    letter-spacing: normal;
    font-size: 0.9em;
}

.toolbar-menu-icon {
    padding-left: 5px!important;
}

.navbar .container {
    padding: 0;
}

.navbar-inverse {
    background-color: #000;
    border-color: #000;
}

/*faun9 - *Entfernen des menuepunkts index bzw Startseite*/
.navbar-nav  #portaltab-index_html {
    display: none;
}

/* TODO: Einsortieren */

.mosaic-grid-row {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
}

/* Carousel auf startseite soll keinen Abstand links und rechts haben - Achtung funktioniert nur wenn Plone Seite "startseite" heißt */
.section-startseite .mosaic-grid-row:first-of-type {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
}

.section-startseite .mosaic-grid-row:first-of-type > div {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
}

/*faun9 - Größe der angezeigten Bilder vereinheitlichen*/
/*klein - 1/4 groesse*/
.col-md-3 img {
    height: 120px;
    width: 100%;
    object-fit: cover;
}
/*groß - 1/2 groessse */
.col-md-6 img {
    height: 240px;
    width: 100%;
    object-fit: cover;
}

/*faun9 - footer Logo anpassen*/
.footer img {
    margin-bottom: 10px;
    object-fit: unset;
    width: 140px;
    height: auto;
}

/*auf Übersicht Syndikate, von Startseite .section-startseite h2 übernommen und halbe Breite*/
.mosaic-tile-content h3 {
	border-top: solid;
	border-top-width: medium;
	border-width: thick;
	color: #000;
	margin-top: 1em;
	padding-top: 0.4em;
	margin-bottom: 0.5em;
	font-size: 1.2em;
	/*width: 50%;*/
}

/*faun9 - Übersicht Vor Ort Auflistung*/
.mosaic-tile-content ul {
	list-style: none;
	padding: 0px;
	margin-left: auto;
	margin-right: auto;
}

/*faun9 - Popup in Vor-Ort*/
div#popup-content {
    box-shadow: 1px 1px 10px black;
    padding: 0px 5px;
}

div#popup-content dl {
    display: none;
}

/*faun9 - Artikel usw, Breite reduzieren*/
/* du6: Nur auf Artikel anwenden? */
.container {
    max-width: 960px;
}

/*Bild große Bildschirme in Artikel*/
/* du6 TODO: Führt dazu das Teaserbild gestretcht wird */
/*@media (min-width:992px) {*/
/*div#viewlet-above-content-title img {*/
    /*margin: 15px 0px;*/
    /*width: 50%;*/
    /*height: 300px;*/
    /*object-fit: cover;*/
    /*}*/
/*}*/

/*Bild kleine Bildschirme in Artikel*/
/*@media (max-width:991px) {*/
/*div#viewlet-above-content-title img {*/
    /*margin: 0px;*/
    /*width: 100%;*/
    /*height: 300px;*/
    /*}*/
/*}*/

/*faun9 - Liste Seitenanzeige*/
.pagination ul li {
	display: inline;
	padding: 0.5em;
	margin-left: auto;
	margin-right: auto;
}

/* faus13 20180524 (wird noch einsortiert, wenn kein Widerspruch besteht) */
.navbar-nav a {
    text-transform: none;
    font-weight: bold;
}

.jumbotron p {
    max-width: 930px; /* Container minus 2 mal 15px padding */
    margin: auto;
}

/* article/news styling */
.portaltype-news-item p {
    font-size: 14pt;
    line-height: 175%;
    text-align: justify;
    hyphens: auto;
}

/* Einfach nur zum aufräumen, sollte einfach aus den Artikeln raus und nur als Teaser gelassen werden. */
.documentDescription.description {
    display: none;
}
